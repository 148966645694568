import {
	WordPressBlocksViewer,
	ContentBlock,
} from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { flatListToHierarchical } from '@faustwp/core/dist/mjs/utils/flatListToHierarchical';
import { Parse } from '@/lib/parser';
import {
	BlockContentEditorBlockFragFragment,
	BlockContentEditorBlockFragFragmentDoc,
} from '@graphqlTypes/graphql';
import { DefaultBlock } from './DefaultBlock';

export const BlockContent = ( {
	blocks,
	fallbackContent,
	isInnerBlock = false,
}: {
	blocks: BlockContentEditorBlockFragFragment[];
	fallbackContent?: string;
	isInnerBlock?: boolean;
} ): JSX.Element => {
	const hierarchicalBlocks = ! isInnerBlock
		? flatListToHierarchical( blocks, {
				childrenKey: 'innerBlocks',
				idKey: 'clientId',
				parentKey: 'parentClientId',
		  } )
		: blocks;

	return (
		<>
			{ !! hierarchicalBlocks?.length && (
				<WordPressBlocksViewer
					blocks={ hierarchicalBlocks as ContentBlock[] }
					fallbackBlock={ DefaultBlock }
				/>
			) }

			{ ! hierarchicalBlocks?.length && !! fallbackContent && <Parse html={ fallbackContent } /> }
		</>
	);
};

BlockContent.fragments = {
	entry: BlockContentEditorBlockFragFragmentDoc,
	key: 'BlockContentEditorBlockFrag',
};
