import { Image as FeaturedImage } from '@/components/elements/Image/Image';
import { cn } from '@/utils/helpers/cn';
import style from './HomeCover.module.scss';
import type { MediaItemFragFragment } from '@graphqlTypes/graphql';

export const HomeCover = ( {
	children,
	image,
	className,
}: {
	children: React.ReactNode;
	image?: MediaItemFragFragment | null;
	className?: string;
} ): JSX.Element => {
	return (
		<header
			className={ cn(
				className,
				'wp-block-post-title relative flex h-[60vh] max-h-[675px] min-h-[480px] overflow-hidden py-12 text-center'
			) }
			style={ {
				marginBottom: 'var(--wp--preset--spacing--50)',
			} }
		>
			{ image && (
				<>
					<FeaturedImage
						image={ image }
						priority
						width={ 1200 }
						height={ 675 }
						fill
						className={ cn( 'absolute inset-0 object-cover' ) }
					/>
					<div className={ cn( 'absolute inset-0 z-0', style.gradient ) } />
				</>
			) }
			{ children && <div className="z-10 mx-auto my-auto">{ children }</div> }
		</header>
	);
};
