import { __ } from '@wordpress/i18n';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { HomeCover } from '@/components/elements/HomeCover/HomeCover';
import { Main } from '@/components/elements/Main/Main';
import { Header } from '@/components/layouts/Header/Header';
import { Layout } from '@/components/layouts/Layout/Layout';
import { BlockContent } from '@/features/WpBlocks/components/BlockContent';
import { getFragmentData } from '@graphqlTypes/fragment-masking';
import {
	FeaturedImageFragFragmentDoc,
	FooterSettingsFragFragmentDoc,
	NodeWithSeoFragFragmentDoc,
	PrimaryMenuItemsFragFragmentDoc,
	SiteIdentityFragFragmentDoc,
	BlockContentEditorBlockFragFragment,
	GetFrontPageNodeQuery,
	MenuItemNodeFragFragment,
	SeoFragFragment,
	MediaItemFragFragmentDoc,
} from '@graphqlTypes/graphql';
import type { FaustTemplate } from '@faustwp/core/dist/mjs/getWordPressProps';

const Footer = dynamic( () =>
	import( '../../components/layouts/Footer/Footer' ).then( ( mod ) => mod.Footer )
);

export const FrontPage: FaustTemplate< GetFrontPageNodeQuery > = ( { data, loading } ) => {
	const identityFrag = getFragmentData( SiteIdentityFragFragmentDoc, data?.siteConfig );
	const logo = getFragmentData(
		MediaItemFragFragmentDoc,
		identityFrag?.identity?.axewpLogoLight?.node
	);

	const footerFrag = getFragmentData( FooterSettingsFragFragmentDoc, data?.siteConfig );
	const footer = footerFrag?.footerSettings?.axewpFooterContent;

	const primaryMenuFrag = getFragmentData( PrimaryMenuItemsFragFragmentDoc, data );
	const menu = primaryMenuFrag?.primaryMenuItems?.nodes;

	const seoFrag = getFragmentData( NodeWithSeoFragFragmentDoc, data?.currentPage );
	const seo = seoFrag?.seo;

	const editorBlocks = data?.currentPage?.editorBlocks;

	const featuredImageFrag = getFragmentData( FeaturedImageFragFragmentDoc, data?.currentPage );
	const featuredImage = getFragmentData(
		MediaItemFragFragmentDoc,
		featuredImageFrag?.featuredImage?.node
	);

	return (
		<>
			<Layout loading={ !! loading } seo={ seo as SeoFragFragment }>
				<Header logo={ logo } menu={ menu as MenuItemNodeFragFragment[] } />
				<Main className="wp-block-group is-layout-constrained">
					<HomeCover image={ featuredImage } className="alignfull">
						<h1 className="wp-block-heading has-white-color has-x-large-font-size text-center font-extrabold tracking-tight">
							{ __( 'Headless ', 'axewp' ) }
							<mark>{ __( 'WordPress', 'axewp' ) }</mark>
							<br />
							{ __( 'Without the headache', 'axewp' ) }
						</h1>
						<div className="is-style-fill wp-block-button mt-4">
							<Link
								className="has-background wp-block-button__link has-blue-100-color has-blue-700-background-color font-bold uppercase"
								href="/contact"
							>
								{ __( 'Get quote', 'axewp' ) }
							</Link>
						</div>
					</HomeCover>
					{ !! editorBlocks?.length && (
						<BlockContent blocks={ editorBlocks as BlockContentEditorBlockFragFragment[] } />
					) }
				</Main>
				{ footer && <Footer content={ footer } /> }
			</Layout>
		</>
	);
};
